import { useState, useCallback, ChangeEvent } from 'react'

const useInput = (initialValue?: string | number) => {
  const [value, setValue] = useState(initialValue ? initialValue : '')
  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => setValue(e.target.value), [setValue])

  return { value, onChange }
}

export default useInput
