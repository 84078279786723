export default (duration: string) => {
  if (!(duration && duration.length > 0)) {
    return '-'
  }

  try {
    const parts = duration.split(':')
    const hours = Number(parts[0])
    const minutes = Number(parts[1])
    const seconds = Number(parts[2])
    return `${hours < 10 ? '0' : ''}${hours.toFixed(0)}:${minutes < 10 ? '0' : ''}${minutes.toFixed(0)}:${seconds < 10 ? '0' : ''}${seconds.toFixed(0)}`
  } catch {
    return '-'
  }
}
