import * as EmailValidator from 'email-validator'
import React, { ChangeEvent, FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Input from './Input'

const nanoid = require('nanoid')
interface Props {
  onChange: (emails: string[]) => void
}

const EmailInputs: FC<Props> = ({ onChange }) => {
  const { t } = useTranslation()

  const [emails, setEmails] = useState<{ [id: string]: { value: string; valid?: boolean } }>({
    [nanoid()]: { value: '' }
  })

  const handleAddNewEmail = () => setEmails({ ...emails, [nanoid()]: '' })

  useEffect(() => {
    const emailsArray = Object.keys(emails).map(id => emails[id]?.value)
    onChange(emailsArray)
  }, [emails, onChange])

  const emailInputs = useMemo(() => {
    const inputs = []
    for (const inputId of Object.keys(emails)) {
      const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setEmails({ ...emails, [inputId]: { value: e.target.value, valid: EmailValidator.validate(e.target.value) } })
      }

      const handleDelete = () => {
        const newEmails: { [id: string]: { value: string; valid?: boolean } } = { ...emails }
        delete newEmails[inputId]
        setEmails(newEmails)
      }

      inputs.push(
        <div key={inputId} className='flex w-full'>
          <Input
            icon='email'
            onChange={handleChange}
            value={emails[inputId]?.value || ''}
            placeholder={t`Enter email address...`}
            valid={emails[inputId]?.valid}
          />
          <button
            onClick={handleDelete}
            disabled={inputs.length === 0}
            className='ml-4 flex-center'
            style={{ opacity: inputs.length === 0 ? 0 : 1 }}
          >
            <i className='material-icons text-xl text-white'>delete</i>
          </button>
        </div>
      )
    }

    return inputs
  }, [emails, setEmails, t])

  return (
    <div className=''>
      {emailInputs}
      <button className='flex-center mt-4' onClick={handleAddNewEmail}>
        <div className='bg-primary rounded-full w-8 h-8 flex-center text-white text-2xl'>+</div>
        <div className='ml-2 text-white font-semibold'>{t`Add email address`}</div>
      </button>
    </div>
  )
}

export default EmailInputs
