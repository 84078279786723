import React, { createContext, FC, useContext, useState, useEffect, useCallback } from 'react'
import firebase from 'firebase/app'
import useRouter from 'hooks/useRouter'
import qs from 'querystring'
import { useAuth } from './AuthProvider'

export interface SessionCodeProviderValue {
  validated: boolean | null
  loading: boolean
  error: boolean
  sessionCode: string | null
  validateSessionCode: (code: string) => Promise<boolean>
}

const SessionCodeContext = createContext<SessionCodeProviderValue>(null as any)

const useSessionCode = () => useContext(SessionCodeContext)
const SessionCodeProvider: FC = ({ children }) => {
  const { user } = useAuth()
  const {
    location: { search }
  } = useRouter()
  const [loading, setLoading] = useState(true)
  const [validated, setValidated] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [sessionCode, setSessionCode] = useState<string | null>(null)

  const validateSessionCode = useCallback(async () => {
    const params = qs.parse(search.slice(1))
    const code = (params?.sessionCode ? String(params.sessionCode) : '').toUpperCase()

    if (code.length === 4 && user) {
      setLoading(true)
      const ref = await firebase
        .firestore()
        .collection('sessions')
        .doc(code)
        .get()
      const data = ref.data()
      if (Boolean(data)) {
        await firebase
          .firestore()
          .collection('sessions')
          .doc(code)
          .update({
            user: user?.uid
          })

        setValidated(true)
        setError(false)
        setLoading(false)
        setSessionCode(code)
        return true
      } else {
        setValidated(false)
        setError(true)
      }

      setLoading(false)
      return false
    } else {
      setLoading(false)
      setValidated(false)
      setError(false)
      return false
    }
  }, [search, user])

  useEffect(() => {
    validateSessionCode()
  }, [validateSessionCode])

  return <SessionCodeContext.Provider value={{ validated, loading, error, sessionCode, validateSessionCode }}>{children}</SessionCodeContext.Provider>
}

export { SessionCodeProvider, SessionCodeContext, useSessionCode }
