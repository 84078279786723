export default async (url: string, filename: string) => {
  const response = await fetch(url)
  const blob = await response.blob()
  const href = window.URL.createObjectURL(blob)

  const a = document.createElement('a')
  a.href = href
  a.download = filename
  a.click()

  return
}
