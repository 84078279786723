import { CircularProgress } from '@material-ui/core'
import VideoDialog from 'components/VideoDialog'
import VideoListItem from 'components/VideoListItem'
import { default as app, default as firebase } from 'firebase/app'
import useSessionVideos from 'firebase/useSessionVideos'
import { Video } from 'models'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router'

interface Props extends RouteComponentProps<void> {}

const SessionVideosRoute: FC<Props> = props => {
  const { videos, loading } = useSessionVideos()
  const { t } = useTranslation()
  const [playedVideo, setPlayedVideo] = useState<Video | null>(null)
  const handleCloseVideoDialog = () => setPlayedVideo(null)

  const [videoUrl, setVideoUrl] = useState<string | null>(null)
  useEffect(() => {
    if (playedVideo) {
      const storage = firebase.storage()
      storage
        .refFromURL(playedVideo.url)
        .getDownloadURL()
        .then(url => setVideoUrl(url))
        .then(() => app.analytics().logEvent('watch_video', { videoId: playedVideo.id }))
    } else {
      setVideoUrl(null)
    }
  }, [playedVideo, setVideoUrl])

  const videoList = useMemo(
    () => videos.map(video => <VideoListItem key={video.id} video={video} onPlayVideo={setPlayedVideo} />),
    [videos]
  )

  const [showVideos, setShowVideos] = useState(false)
  useEffect(() => {
    if (!loading) {
      setTimeout(() => setShowVideos(true), 300)
    }
  }, [loading])

  return (
    <div className='flex items-center flex-col overflow-y-auto p-3'>
      <div style={{ maxWidth: 800 }} className='w-full'>
        <h1 className='text-center'>{t`Your videos`}</h1>
        <p className='mb-6 mt-3'>{t`Videos text`}</p>
        <div className='transition-opacity-slow' style={{ opacity: showVideos ? 1 : 0 }}>
          {videoList}
        </div>
        {loading && (
          <div className='w-full flex-center p-5'>
            <CircularProgress size={40} style={{ color: '#fff' }} />
          </div>
        )}
        <VideoDialog open={Boolean(playedVideo)} videoUrl={videoUrl} onClose={handleCloseVideoDialog} />
      </div>
    </div>
  )
}

export default SessionVideosRoute
