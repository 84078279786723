import React, { FC } from 'react'
import { Dialog as MuiDialog, CircularProgress } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

interface Props {
  open: boolean
  title?: string
  fullScreen?: boolean
  loading?: boolean
  onConfirm?: () => void
  onClose: () => void
}

const Dialog: FC<Props> = ({ open, title, loading, onClose, onConfirm, fullScreen, children }) => {
  const { t } = useTranslation()
  return (
    <MuiDialog fullScreen={fullScreen} open={open} onClose={onClose}>
      <div className='p-4 flex flex-col h-full'>
        {title && <div className='text-gray-800 text-3xl font-semibold'>{title}</div>}
        <div className='text-lg text-gray-600 my-2 flex-1 relative'>{children}</div>
      </div>
      <div className='flex w-full justify-end bg-gray-300 p-2'>
        <button disabled={loading} className='py-2 px-3 text-gray-600 text-base font-semibold' onClick={onClose}>
          {t`Cancel`}
        </button>
        <div className='w-4' />
        {onConfirm && (
          <button disabled={loading} className='bg-green-600 py-2 px-3 rounded text-white text-base font-semibold flex-center' onClick={onConfirm}>
            {loading && <CircularProgress size={16} style={{ color: 'white', marginRight: 5 }} />}
            {t`Confirm`}
          </button>
        )}
      </div>
    </MuiDialog>
  )
}

export default Dialog
