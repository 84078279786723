import React, { FC, useState, useRef } from 'react'
import Dialog from './Dialog'
import useInput from 'hooks/useInput'
import { useTranslation } from 'react-i18next'
import request from 'request-promise-lite'
import { useSnackbar } from 'providers/SnackbarProvider'

interface Props {
  sessionCode: string
  open: boolean
  onClose: () => void
}

const EmailSessionCodeDialog: FC<Props> = ({ sessionCode, open, onClose }) => {
  const { t } = useTranslation()
  const inputProps = useInput()
  const inputRef = useRef<any>(null)

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const handleClose = () => {
    setError(null)
    setLoading(false)
    if (inputRef.current?.value) {
      inputRef.current.value = ''
    }
    onClose()
  }

  const { show } = useSnackbar()
  const handleSendSessionCode = async () => {
    setLoading(true)
    try {
      const req = new request.Request(
        'POST',
        `${process.env.NODE_ENV === 'production' ? 'https://labbstation-rymden.appspot.com' : 'http://localhost:8080'}/api/v1/sessionCodeEmail`,
        {
          qs: { sessionCode, email: inputProps.value, language: localStorage.getItem('language') || 'EN' }
        }
      )
      await req.run()
      show({ message: t('Link successfully sent to your email address!'), icon: 'email' })
      setLoading(false)
      handleClose()
    } catch (e) {
      setLoading(false)
      setError(t('Something went wrong when trying to send you the link.'))
    }
  }

  return (
    <Dialog title={t`Send link to email address`} open={open} onClose={handleClose} onConfirm={handleSendSessionCode} loading={loading}>
      <div className='mb-3'>{t`Enter your email address below and you will receive a mail with a link to the session page`}</div>
      <div className='relative w-full'>
        <i className='material-icons ml-3 absolute left-0 top-0 bottom-0 flex-center'>email</i>
        <input ref={inputRef} placeholder={t`Your email address...`} className='bg-gray-200 text-gray-900 text-lg rounded-lg p-3 pl-12 w-full' {...inputProps} type='email' />
      </div>
      {error && <div className='text-red-600 text-lg font-semibold mt-3'>{error}</div>}
    </Dialog>
  )
}

export default EmailSessionCodeDialog
