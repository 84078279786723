import { Slider, CircularProgress } from '@material-ui/core'
import EmailInputs from 'components/EmailInputs'
import Timeline from 'components/Timeline'
import React, { FC, useCallback, useState, ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router'
import Input from 'components/Input'
import { useSnackbar } from 'providers/SnackbarProvider'
import * as EmailValidator from 'email-validator'

interface Props extends RouteComponentProps<void> {}

const PreGenerateSessionCodes: FC<Props> = ({ children }) => {
  const { t } = useTranslation()

  const [title, setTitle] = useState('')
  const handleChangeTitle = useCallback((e: ChangeEvent<HTMLInputElement>) => setTitle(e.target.value), [setTitle])

  const [emails, setEmails] = useState<string[]>([])
  const handleEmailsChange = useCallback(
    (_emails: string[]) => {
      setEmails(_emails)
    },
    [setEmails]
  )

  const [numCodes, setNumCodes] = useState(10)
  const handleChangeNumCodes = (e: any, codes: number | number[]) => setNumCodes(codes as number)

  const { show } = useSnackbar()
  const [loading, setLoading] = useState(false)

  const [successfullyCreatedCodes, setSuccessfullyCreatedCodes] = useState(false)
  const handleCreateNewCodes = () => {
    setNumCodes(10)
    setEmails([])
    setTitle('')
    setSuccessfullyCreatedCodes(false)
  }

  const handleSubmit = async () => {
    try {
      setLoading(true)
      for (const email of emails) {
        if (!EmailValidator.validate(email)) {
          show({ message: t`All emails are not valid.` })
          setLoading(false)
          return
        }
      }

      const emailsStr = emails.join()

      const baseUrl = `${
        process.env.NODE_ENV === 'production' ? 'https://labbstation-rymden.appspot.com' : 'http://localhost:8080'
      }`

      const { status } = await fetch(
        `${baseUrl}/api/v1/sessions?emailAddresses=${emailsStr}${title &&
          `&title=${title}`}&sessionCodeCount=${numCodes}&language=SE`,
        {
          method: 'POST'
        }
      )

      if (status !== 200) {
        throw new Error('Unknown error.')
      }

      setLoading(false)
      setSuccessfullyCreatedCodes(true)
    } catch (e) {
      setLoading(false)
      show({ message: t`Something went wrong when trying to send codes`, icon: 'cancel' })
    }
  }

  if (successfullyCreatedCodes) {
    return (
      <div className='flex-center flex-col absolute inset-0'>
        <div className='flex items-center px-5'>
          <div className='w-16 h-16 rounded-full flex-none flex-center bg-primary mr-4'>
            <i className='material-icons text-white text-4xl'>check</i>
          </div>
          <h1 className='text-center'>{t('Codes successfully created')}</h1>
        </div>
        <div className='mt-6'>
          <button
            onClick={handleCreateNewCodes}
            className='font-bold text-white text-xl border border-white rounded-full px-6 py-2'
          >{t`Generate new codes`}</button>
        </div>
      </div>
    )
  }

  return (
    <div className='flex items-center justify-center flex-col overflow-y-auto p-3 relative'>
      {loading && <div className='absolute inset-0 z-20 pointer-events-all' />}
      <div style={{ maxWidth: 800, opacity: loading ? 0.5 : 1 }} className='w-full'>
        <h1 className='text-center'>{t('Pre generate codes')}</h1>
        <p className='mb-6 mt-3'>{t`Here you can generate codes`}</p>
        <div className='p-4 w-full mx-auto' style={{ maxWidth: 600 }}>
          <Timeline
            steps={[
              {
                title: t`Title`,
                description: t`Enter a title which will be sent in the emails as a identifier.`,
                content: (
                  <Input placeholder={t`Enter title...`} className='mr-8' onChange={handleChangeTitle} value={title} />
                )
              },
              {
                title: t`Enter email addresses`,
                description: t`Enter the email adresses you want the session codes to be sent to.`,
                content: (
                  <div className=''>
                    <EmailInputs onChange={handleEmailsChange} />
                  </div>
                )
              },
              {
                title: (
                  <>
                    {t`Number of session codes`}: {numCodes}
                  </>
                ),
                description: t`Enter the number of session codes to be generated by dragging the slider below.`,
                content: (
                  <div className='flex items-center mt-12 w-full'>
                    <Slider
                      defaultValue={10}
                      aria-labelledby='discrete-slider-small-steps'
                      step={1}
                      min={1}
                      max={50}
                      valueLabelDisplay='on'
                      onChange={handleChangeNumCodes}
                    />
                  </div>
                )
              },
              {
                content: (
                  <div className='w-full'>
                    <button
                      onClick={handleSubmit}
                      style={{ fontFamily: 'GothamRoundedBold' }}
                      disabled={loading}
                      className='bg-primary text-white mt-3 flex-center rounded-lg shadow-lg text-lg h-12 px-4'
                    >
                      <div>{t`Generate codes and send to email addresses`}</div>
                      {loading && <CircularProgress style={{ color: 'white', marginLeft: 15 }} size={16} />}
                    </button>
                  </div>
                )
              }
            ]}
          />
        </div>
        <button></button>
      </div>
    </div>
  )
}

export default PreGenerateSessionCodes
