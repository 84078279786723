import logo from 'assets/logo.svg'
import LanguageSelector from 'components/LanguageSelector'
import useRouter from 'hooks/useRouter'
import { useSessionCode } from 'providers/SessionCodeProvider'
import React, { FC } from 'react'
import { Route, Switch, Redirect } from 'react-router'
import { animated, useTransition } from 'react-spring'
import InitSessionRoute from 'routes/InitSessionRoute'
import PreGenerateSessionCodes from 'routes/PreGenerateSessionCodes'
import QRCodeRoute from 'routes/QRCodeRoute'
import SessionVideosRoute from 'routes/SessionVideosRoute'
import ShareVideoRoute from 'routes/ShareVideoRoute'

const App: FC = () => {
  const { loading, validated } = useSessionCode()
  const {
    location,
    location: { search, pathname },
    history
  } = useRouter()

  const transitions = useTransition(location, location => location.pathname, {
    initial: { transform: 'scale(1)', opacity: 1 },
    from: { transform: 'scale(1.1)', opacity: 0 },
    enter: { transform: 'scale(1)', opacity: 1 },
    leave: { transform: 'scale(1.1)', opacity: 0 }
  })

  const handleBack = () => history.push('/')

  return (
    <div className='absolute inset-0 z-10 flex flex-col'>
      {pathname.indexOf('video/') === -1 && (
        <div className='flex justify-center flex-none' style={{ height: 80 }}>
          <div className='w-12 p-2'>
            {pathname !== '/' && (
              <button onClick={handleBack} className='w-10 h-10 rounded-full flex-center'>
                <i className='material-icons text-white text-3xl'>arrow_back</i>
              </button>
            )}
          </div>
          <div className='flex-1' />
          <img src={logo} alt='logo' width={150} />
          <div className='flex-1' />
          <div className='w-12 p-2 mr-2'>
            <LanguageSelector />
          </div>
        </div>
      )}
      <div className='relative flex-1 flex'>
        {!loading && !validated && pathname !== '/generate-codes' && pathname.indexOf('video/') === -1 && (
          <Redirect to={`${search}`} />
        )}
        {transitions.map(({ item, props, key }) => (
          <animated.div className='absolute inset-0 overflow-y-auto' style={props} key={key}>
            <Switch location={item}>
              <Route path='/video/:videoId' component={ShareVideoRoute} />
              <Route path='/videos' component={SessionVideosRoute} />
              <Route path='/qr' component={QRCodeRoute} />
              <Route path='/generate-codes' component={PreGenerateSessionCodes} />
              <Route path='/' component={InitSessionRoute} />
            </Switch>
          </animated.div>
        ))}
      </div>
    </div>
  )
}

export default App
