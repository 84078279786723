import { CircularProgress, Tooltip } from '@material-ui/core'
import { format } from 'date-fns'
import { enUS, sv } from 'date-fns/locale'
import { default as app, default as firebase } from 'firebase/app'
import { Video } from 'models'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Img from 'react-image'
import downloadUrl from 'utils/downloadUrl'
import parseDurationString from 'utils/parseDurationString'
import VideoShareButton from './VideoShareButton'

const locales = { 'sv-SE': sv, en: enUS }

interface Props {
  video: Video
  onPlayVideo: (video: Video) => void
}

const VideoListItem: FC<Props> = ({ video, onPlayVideo }) => {
  const [thumbnailUrl, setThumbnailUrl] = useState('')
  useEffect(() => {
    const storage = firebase.storage()
    storage
      .refFromURL(video.thumbnailUrl)
      .getDownloadURL()
      .then(url => setThumbnailUrl(url))
  }, [video, setThumbnailUrl])

  const [downloading, setDownloading] = useState(false)
  const handleDownloadVideo = async () => {
    setDownloading(true)

    try {
      const storage = firebase.storage()
      const url = await storage.refFromURL(video.url).getDownloadURL()
      const filename = `video_universeum_${format(video.createdAt.seconds * 1000, 'yyyy_MM_dd_HH_mm')}.mp4`
      app.analytics().logEvent('video_download', { videoId: video.id })
      await downloadUrl(url, filename)
    } catch (e) {
      console.error(e)
    }

    setDownloading(false)
  }

  const { i18n, t } = useTranslation()

  const formattedDate = format(video.createdAt.seconds * 1000, 'do MMM yyyy HH:mm', {
    locale: locales[i18n.language as 'sv-SE' | 'en']
  })

  const [imageLoaded, setImageLoaded] = useState(false)
  const handleImageLoaded = () => setTimeout(() => setImageLoaded(true), 200)

  return (
    <div
      className='p-4 my-3 text-gray-200 text-base flex items-center rounded-lg'
      style={{ background: 'rgba(0,0,0,0.4)' }}
    >
      <div
        onClick={() => onPlayVideo(video)}
        style={{ width: 160 / 1.5, height: 90 / 1.5 }}
        className='rounded relative mr-5 bg-gray-400 flex-center overflow-hidden'
      >
        <Img
          onLoad={handleImageLoaded}
          style={{ opacity: imageLoaded ? 1 : 0 }}
          className='w-full transition-opacity-slow'
          src={thumbnailUrl}
          alt='thumbnail'
        />
        <div className='absolute inset-0 flex-center video-thumbnail'>
          <i className='material-icons text-4xl'>play_arrow</i>
        </div>
      </div>
      <div className='flex-1 flex flex-col'>
        <div className='font-semibold'>{formattedDate}</div>

        <Tooltip title={t('File size')}>
          <div className='items-center flex w-fit'>
            <i className='material-icons text-base mr-1'>insert_drive_file</i>
            <div className='mr-5'>{video.size ? `${(video.size / 1000000).toFixed(1)} mb` : '- mb'}</div>
          </div>
        </Tooltip>

        <Tooltip title={t('Video duration')}>
          <div className='items-center flex w-fit'>
            <i className='material-icons text-base mr-1'>schedule</i>
            <div>{parseDurationString(video.duration)}</div>
          </div>
        </Tooltip>
      </div>
      <div className='flex-none flex'>
        <Tooltip title={t('Download video')}>
          <button disabled={downloading} onClick={handleDownloadVideo} className='w-10 h-10 rounded-lg flex-center'>
            {!downloading && <i className='material-icons text-gray-200 text-xl'>cloud_download</i>}
            {downloading && <CircularProgress size={16} style={{ color: '#fff' }} />}
          </button>
        </Tooltip>
      </div>
      <div className='flex-none'>
        <VideoShareButton videoId={video.id} />
      </div>
    </div>
  )
}

export default VideoListItem
