import 'firebase/auth'
import 'firebase/firebase-firestore'

import app from 'firebase/app'
import React, { FC, useContext, useEffect, useRef, useState } from 'react'

interface ProviderValue {
  isAuthenticated: boolean
  user: firebase.User | null
  loading: boolean
  signOut: () => void
}

const AuthContext = React.createContext<ProviderValue>(null as any)

const useAuth = () => useContext(AuthContext)

const AuthProvider: FC = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [user, setUser] = useState<firebase.User | null>(null)
  const [loading, setLoading] = useState(true)

  const auth = useRef<firebase.auth.Auth>(app.auth())

  useEffect(() => {
    auth.current.signInAnonymously().catch(error => {
      console.log(error)
    })

    auth.current.onAuthStateChanged(async user => {
      setLoading(true)
      if (user) {
        const idToken = await user.getIdToken(true)
        localStorage.setItem('idToken', idToken)
        setUser(user)
        setIsAuthenticated(true)
      } else {
        setUser(null)
        setIsAuthenticated(false)
      }
      setLoading(false)
    })
  }, [])

  const signOut = async () => {
    setLoading(true)
    await auth.current.signOut()
    setLoading(false)
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        loading,
        user,
        signOut
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export { AuthProvider, useAuth }
