import './i18n'
import 'styles/global.css'
import 'styles/tailwind.css'

import app from 'firebase/app'
import 'firebase/analytics'
import 'firebase/storage'
import { createBrowserHistory } from 'history'
import { AuthProvider, useAuth } from 'providers/AuthProvider'
import { SessionCodeProvider } from 'providers/SessionCodeProvider'
import React, { FC } from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router'
import spaceBg from 'assets/cosmos.jpg'

import App from './App'
import * as serviceWorker from './serviceWorker'
import LoadingPage from 'components/LoadingPage'
import { SnackbarProvider } from 'providers/SnackbarProvider'
import { ThemeProvider, createMuiTheme } from '@material-ui/core'

const firebaseConfig = {
  apiKey: 'AIzaSyA_wFAeio0qvY4tAxYkyp_4gwnSko93_u4',
  authDomain: 'labbstation-rymden.firebaseapp.com',
  databaseURL: 'https://labbstation-rymden.firebaseio.com',
  projectId: 'labbstation-rymden',
  storageBucket: 'labbstation-rymden.appspot.com',
  messagingSenderId: '704071707946',
  appId: '1:704071707946:web:d6c568f101936370af0253',
  measurementId: 'G-WRYYBC3MMX'
}

app.initializeApp(firebaseConfig)
app.analytics()

const history = createBrowserHistory()

const Main: FC = () => {
  const { loading, user } = useAuth()
  return (
    <div
      style={{ backgroundImage: `url('${spaceBg}')` }}
      className='absolute inset-0 bg-center bg-cover bg-no-repeat bg-fixed overflow-hidden'
    >
      <div className='absolute inset-0' style={{ background: 'rgba(0,0,0,0.6)' }} />
      {loading && <LoadingPage />}
      {!loading && user && <App />}
    </div>
  )
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#73c72c'
    }
  }
})

ReactDOM.render(
  <Router history={history}>
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <SessionCodeProvider>
          <SnackbarProvider>
            <Main />
          </SnackbarProvider>
        </SessionCodeProvider>
      </AuthProvider>
    </ThemeProvider>
  </Router>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
