import React, { FC, useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import ReactCodeInput from 'react-code-input'
import { CircularProgress } from '@material-ui/core'
import { animated, useSpring, config } from 'react-spring'

interface Props {
  value?: string
  loading?: boolean
  error: boolean
  onChange: (sessionCode: string) => void
}

const CodeInput: FC<Props> = ({ value, loading, error, onChange }) => {
  const baseStyles = {
    fontFamily: 'GothamRoundedBold',
    margin: '5px',
    MozAppearance: 'textfield',
    width: '65px',
    height: '65px',
    background: 'transparent',
    border: '2px solid #fff',
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    textAlign: 'center',
    fontSize: 40,
    transition: 'border 0.25s ease'
  }

  const props = {
    className: 'flex-center',
    inputStyle: {
      ...baseStyles
    },
    inputStyleInvalid: {
      ...baseStyles,
      border: '2px solid #e53e3e'
    }
  }

  const [errorAnimationState, setErrorAnimationState] = useState(0)
  const errorAnimationStateRef = useRef(0)
  useEffect(() => {
    if (error) {
      errorAnimationStateRef.current = errorAnimationStateRef.current === 0 ? 1 : 0
      setErrorAnimationState(errorAnimationStateRef.current)
    }
  }, [error])

  const { x } = useSpring({ from: { x: 0 }, x: errorAnimationState, config: config.wobbly })
  const { t } = useTranslation()

  return (
    <div className='flex-center flex-col'>
      <div className='w-full flex items-center justify-center'>
        <animated.div
          className='relative'
          style={{
            transform: x
              .interpolate({
                range: [0, 0.33, 0.66, 1],
                output: [0, -0.5, 1, 0]
              })
              .interpolate(x => `translateX(${x * 10}px)`)
          }}
        >
          <ReactCodeInput value={value} isValid={!error} fields={4} onChange={onChange} {...props} />
        </animated.div>
      </div>
      {loading && (
        <div className='flex mt-3 h-12'>
          <CircularProgress style={{ color: '#fff' }} size={30} />}
        </div>
      )}
      {!loading && (
        <div style={{ opacity: error ? 1 : 0 }} className='mt-3 text-center text-red-600 font-semibold text-lg h-12 transition-opacity'>
          {error && t('Bad session code')}
        </div>
      )}
    </div>
  )
}

export default CodeInput
