import { Popover, Tooltip } from '@material-ui/core'
import app from 'firebase/app'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton
} from 'react-share'

interface Props {
  videoId: string | undefined
}

const VideoShareButton: FC<Props> = ({ videoId }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const handleOpen = (event: any) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)
  const { t } = useTranslation()
  const shareEvent = () => app.analytics().logEvent('share_social', { videoId })

  return (
    <>
      <Tooltip title={t`Share video`}>
        <button onClick={handleOpen} className='w-10 h-10 rounded-lg flex-center'>
          <i className='material-icons text-gray-200 text-xl'>share</i>
        </button>
      </Tooltip>
      <Popover
        id='sharePopover'
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <div className='flex flex-col w-40'>
          <FacebookShareButton onClick={shareEvent} url={`https://minresa.universeum.se/video/${videoId}`}>
            <div className='flex text-gray-800 text-xs font-semibold my-1 p-2 items-center hover:bg-gray-200'>
              <FacebookIcon size={20} style={{ marginRight: 7 }} />
              Facebook
            </div>
          </FacebookShareButton>
          <TwitterShareButton onClick={shareEvent} url={`https://minresa.universeum.se/video/${videoId}`}>
            <div className='flex text-gray-800 text-xs font-semibold my-1 p-2 items-center hover:bg-gray-200'>
              <TwitterIcon size={20} style={{ marginRight: 7 }} />
              Twitter
            </div>
          </TwitterShareButton>
          <LinkedinShareButton onClick={shareEvent} url={`https://minresa.universeum.se/video/${videoId}`}>
            <div className='flex text-gray-800 text-xs font-semibold my-1 p-2 items-center hover:bg-gray-200'>
              <LinkedinIcon size={20} style={{ marginRight: 7 }} />
              LinkedIn
            </div>
          </LinkedinShareButton>
          <EmailShareButton
            subject={t`Video created at Universeum!`}
            body={t`I have created a video at Universeum - click the link to watch.`}
            separator=' '
            url={`https://minresa.universeum.se/video/${videoId}`}
          >
            <div
              // onClick={shareEvent}
              className='flex text-gray-800 text-xs font-semibold my-1 p-2 items-center hover:bg-gray-200'
            >
              <EmailIcon size={20} style={{ marginRight: 7 }} />
              Email
            </div>
          </EmailShareButton>
        </div>
      </Popover>
    </>
  )
}

export default VideoShareButton
