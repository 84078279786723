import React, { FC } from 'react'
import { Dialog as MuiDialog, CircularProgress } from '@material-ui/core'

interface Props {
  open: boolean
  videoUrl: string | null
  onClose: () => void
}

const VideoDialog: FC<Props> = ({ open, videoUrl, onClose }) => {
  return (
    <MuiDialog classes={{ paper: 'overflow-hidden' }} open={open} onClose={onClose}>
      <div className='bg-black flex-center overflow-hidden w-full relative'>
        {videoUrl && (
          <>
            <video autoPlay={true} controls={true} src={videoUrl ? videoUrl : ''} className='w-full' />
            <button onClick={onClose} className='absolute top-0 right-0 m-2 rounded-full p-1'>
              <i className='material-icons text-white text-2xl'>close</i>
            </button>
          </>
        )}
        {!videoUrl && <CircularProgress size={30} style={{ color: '#fff' }} />}
      </div>
    </MuiDialog>
  )
}

export default VideoDialog
