import React, { FC, ReactNode } from 'react'

interface Props {
  steps: { title?: string | ReactNode; description?: string | ReactNode; content: ReactNode }[]
}

const Timeline: FC<Props> = ({ steps }) => {
  const timelineSteps = steps.map(({ title, description, content }, index) => (
    <div key={index} className='flex'>
      <div className='flex-none flex flex-col items-center mr-2'>
        {index !== 0 && <div className='w-1 bg-primary h-8' />}
        <div className='bg-primary rounded-full w-8 h-8 text-white font-semibold flex-center'>{index + 1}</div>
        {index !== steps.length - 1 && (
          <>
            <div className='w-1 bg-primary flex-1' style={{ minHeight: 150 }} />
            <div className='w-1 border-2 border-dashed border-primary flex-none' style={{ height: 100 }} />
          </>
        )}
      </div>
      <div className='flex px-3 flex-col w-full'>
        {title && (
          <div
            style={{ fontFamily: 'GothamRoundedBold' }}
            className={`h-8 ${index !== 0 && 'mt-8'} flex items-center text-white text-2xl`}
          >
            {title}
          </div>
        )}
        {description && <div className='text-lg mt-0 text-white'>{description}</div>}
        {!title && <div className='h-18' />}
        <div className='my-3'>{content}</div>
      </div>
    </div>
  ))
  return <div className='flex flex-col'>{timelineSteps}</div>
}

export default Timeline
