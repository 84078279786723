import React, { createContext, FC, ReactNode, useRef, useContext } from 'react'

import MessageHub from 'components/MessageHub'

const nanoid = require('nanoid')

export interface SnackbarParams {
  message: string | ReactNode
  icon?: string
  actions?: any[]
}

export interface SnackbarProviderValue {
  show: (params: SnackbarParams) => void
  clear: () => void
}

const SnackbarContext = createContext<SnackbarProviderValue>(null as any)

const useSnackbar = () => useContext(SnackbarContext)
const SnackbarProvider: FC = ({ children }) => {
  const messageHubRef: any = useRef(null)

  const show = (params: SnackbarParams) => messageHubRef.current({ ...params, key: nanoid() })
  const clear = () => messageHubRef.current.clear()

  return (
    <SnackbarContext.Provider value={{ show, clear }}>
      {children}
      <MessageHub children={(add: any) => (messageHubRef.current = add)} />
    </SnackbarContext.Provider>
  )
}

export { SnackbarProvider, SnackbarContext, useSnackbar }
