import CodeInput from 'components/CodeInput'
import useRouter from 'hooks/useRouter'
import { useSessionCode } from 'providers/SessionCodeProvider'
import qs from 'querystring'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect, RouteComponentProps } from 'react-router'

interface Props extends RouteComponentProps<void> {}

const InitSessionRoute: FC<Props> = props => {
  const { t } = useTranslation()

  const { validated, loading, error } = useSessionCode()
  const {
    history,
    location: { search }
  } = useRouter()

  const [currentCode, setCurrentCode] = useState(() => {
    const params = qs.parse(search.slice(1))
    return params?.sessionCode ? String(params.sessionCode) : ''
  })

  const handleSessionCodeChange = async (sessionCode: string) => {
    setCurrentCode(sessionCode)
    if (sessionCode.length === 4) {
      history.push({ search: qs.stringify({ sessionCode: sessionCode.toUpperCase() }) })
    }
  }

  return (
    <div style={{ maxWidth: 800 }} className='absolute inset-0 flex items-center justify-center flex-col m-auto p-3'>
      {validated && <Redirect to={`/videos${search}`} />}
      <h1 className='mb-3 text-center'>{t('Enter session code')}</h1>
      <p className='mb-6'>{t('Introduction text')}</p>
      <CodeInput value={currentCode} error={error} loading={loading} onChange={handleSessionCodeChange} />
    </div>
  )
}

export default InitSessionRoute
