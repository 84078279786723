import React, { FC, useState } from 'react'
import { Popover } from '@material-ui/core'
import seFlag from 'assets/sweden.svg'
import enFlag from 'assets/united-kingdom.svg'
import { useTranslation } from 'react-i18next'

interface Props {}

const LanguageSelector: FC<Props> = ({ children }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const handleOpen = (event: any) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)
  const { i18n } = useTranslation()

  const languages: { [lang: string]: { title: string; img: string } } = {
    'sv-SE': { title: 'Svenska', img: seFlag },
    en: { title: 'English', img: enFlag }
  }

  const handleChangeLang = (countryCode: string) => {
    localStorage.setItem('language', countryCode)
    i18n.changeLanguage(countryCode)
  }

  return (
    <>
      <button onClick={handleOpen} className='w-10 h-10 flex-center'>
        <img width={25} src={languages[i18n.language]?.img ?? languages['sv-SE'].img} alt='lang' />
      </button>
      <Popover
        id='languagePopover'
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <div className='w-32 flex flex-col'>
          {Object.keys(languages).map(lang => (
            <button
              key={lang}
              onClick={() => handleChangeLang(lang)}
              className='p-3 text-base text-gray-900 text-left hover:bg-gray-200 flex items-center'
            >
              <img width={20} src={languages[lang]?.img ?? languages['sv-SE'].img} alt={lang} />
              <div className='ml-2 text-xs text-gray-700'>{languages[lang].title}</div>
              <div className='flex-1' />
              <i className='material-icons text-base text-gray-600'>
                {i18n.language === lang ? 'radio_button_checked' : 'radio_button_unchecked'}
              </i>
            </button>
          ))}
        </div>
      </Popover>
    </>
  )
}

export default LanguageSelector
