import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-locize-backend'

i18n
  .use(Backend) // passes i18n down to react-i18next
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: (localStorage.getItem('language') === 'sv-SE' || localStorage.getItem('language') === 'en'
      ? localStorage.getItem('language')
      : 'sv-SE') as string,
    fallbackLng: 'sv-SE',
    backend: {
      projectId: '49c8cd89-52dc-4e80-9b85-568d97c2c7e2',
      apiKey: 'f3c9151f-b394-40bc-a3ab-ef784b2dd05a',
      referenceLng: 'sv-SE'
    },
    keySeparator: false, // we do not use keys in form messages
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    ns: ['web-portal'],
    defaultNS: 'web-portal'
  })

export default i18n
