import { default as app, default as firebase } from 'firebase/app'
import { Video } from 'models'
import { useSessionCode } from 'providers/SessionCodeProvider'
import { useEffect, useState } from 'react'

const useSessionVideos = () => {
  const { sessionCode } = useSessionCode()
  const [loading, setLoading] = useState(true)
  const [videos, setVideos] = useState<Video[]>([])

  useEffect(() => {
    if (sessionCode) {
      app.analytics().setUserId(sessionCode)
      const unsubscribe = firebase
        .firestore()
        .collection('videos')
        .where('sessionCode', '==', sessionCode)
        .orderBy('createdAt', 'desc')
        .onSnapshot(async snapshot => {
          const _videos: Video[] = []
          snapshot.forEach(doc => {
            const videoData: any = doc.data()
            return _videos.push({ ...videoData, id: doc.id })
          })
          setVideos(_videos)
          setLoading(false)
        })

      return () => unsubscribe()
    }
    return () => null
  }, [sessionCode])

  return { videos, loading }
}

export default useSessionVideos
