import { CircularProgress } from '@material-ui/core'
import React, { FC } from 'react'

const Loadingpage: FC = props => {
  return (
    <div className='absolute inset-0 flex-center'>
      <CircularProgress size={50} style={{ color: '#fff' }} />
    </div>
  )
}

export default Loadingpage
