import React, { FC, useEffect, useState } from 'react'
import { animated, useTransition } from 'react-spring'

const config = { tension: 125, friction: 20, precision: 0.1 }
const timeout = 3000
let id = 0

const useTransition2: any = useTransition

interface Props {
  children: any
}

const MessageHub: FC<Props> = ({ children }) => {
  const [refMap] = useState(() => new WeakMap())
  const [cancelMap] = useState(() => new WeakMap())
  const [items, setItems] = useState<any>([])
  const transitions = useTransition2(items, (item: any) => item.key, {
    from: { opacity: 0, height: 0, life: '100%' },
    enter: (item: any) => async (next: any) => next({ opacity: 1, height: refMap.get(item).offsetHeight }),
    leave: (item: any) => async (next: any, cancel: any) => {
      cancelMap.set(item, cancel)
      await next({ life: '0%' })
      await next({ opacity: 0 })
      await next({ height: 0 })
    },
    onRest: (item: any) => setItems((state: any) => state.filter((i: any) => i.key !== item.key)),
    config: (item: any, state: any) => (state === 'leave' ? [{ duration: timeout }, config, config] : config)
  })

  useEffect(() => void children((msg: any) => setItems((state: any) => [...state, { key: id += 1, ...msg }])), [
    children
  ])

  return (
    <div className='pointer-events-none fixed z-20 left-0 bottom-0 right-0 flex flex-col items-end p-4'>
      {transitions.map(({ key, item, props: { life, ...style } }: any) => (
        <animated.div key={key} className='w-64 my-1 mx-auto' style={style}>
          <div
            className='bg-gray-700 shadow-xl rounded-lg flex mt-2 text-white relative p-4'
            ref={ref => ref && refMap.set(item, ref)}
          >
            {item.icon && (
              <i className='material-icons' style={{ margin: '5px', marginRight: '10px', fontSize: '18px' }}>
                {item.icon}
              </i>
            )}
            <div className='flex-1 h-full flex items-center text-base'>{item.message}</div>
            {Boolean(item.actions) && item.actions}
          </div>
        </animated.div>
      ))}
    </div>
  )
}

export default MessageHub
