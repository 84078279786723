import firebase from 'firebase/app'
import { Video } from 'models'
import { useCallback, useEffect, useState } from 'react'

const useSessionVideo = (videoId: string) => {
  const [loading, setLoading] = useState(true)
  const [video, setVideo] = useState<Video | null>(null)
  const [videoUrl, setVideoUrl] = useState<string | null>(null)
  const [thumbnailUrl] = useState<string | null>(null)

  const getVideo = useCallback(async () => {
    if (videoId) {
      setLoading(true)
      const ref = await firebase
        .firestore()
        .collection('videos')
        .doc(videoId)
        .get()
      const _video = ref.data() as Video

      const storage = firebase.storage()
      const _videoUrl = await storage.refFromURL(_video.url).getDownloadURL()
      // const thumbnailUrl = await storage.refFromURL(_video.thumbnailUrl).getDownloadURL()

      if (Boolean(_video)) {
        setVideo(_video)
      }

      if (Boolean(_videoUrl)) {
        setVideoUrl(_videoUrl)
      }

      if (Boolean()) setLoading(false)
    }
  }, [videoId])

  useEffect(() => {
    getVideo()
  }, [getVideo])

  return { video, videoUrl, loading, thumbnailUrl }
}

export default useSessionVideo
