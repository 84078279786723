import { CircularProgress } from '@material-ui/core'
import EmailSessionCodeDialog from 'components/EmailSessionCodeDialog'
import useRouter from 'hooks/useRouter'
import { useSessionCode } from 'providers/SessionCodeProvider'
import React, { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router'

interface Props extends RouteComponentProps<void> {}

const QRCodeRoute: FC<Props> = props => {
  const { sessionCode, loading } = useSessionCode()
  const { t } = useTranslation()

  const { history } = useRouter()
  const handleShowVideos = () => history.push(`/videos?sessionCode=${sessionCode}`)

  const [emailSessionCodeDialogOpen, setEmailSessionCodeDialogOpen] = useState(false)
  const handleOpenSessionCodeDialog = useCallback(() => setEmailSessionCodeDialogOpen(true), [
    setEmailSessionCodeDialogOpen
  ])
  const handleCloseSessionCodeDialog = useCallback(() => setEmailSessionCodeDialogOpen(false), [
    setEmailSessionCodeDialogOpen
  ])

  return (
    <div className='flex items-center flex-col overflow-y-auto p-3'>
      <div style={{ maxWidth: 800 }} className='w-full'>
        <h1 className='text-center'>{t('Your session')}</h1>
        {sessionCode && (
          <div className='text-white text-center text-2xl font-semibold mb-3'>Sessionskod: {sessionCode}</div>
        )}
        {loading && (
          <div className='w-full flex-center p-5'>
            <CircularProgress size={40} style={{ color: '#fff' }} />
          </div>
        )}
        {!loading && (
          <div className='flex flex-col w-full'>
            <button
              onClick={handleShowVideos}
              className='p-5 my-2 text-gray-200 text-base flex items-center rounded-lg text-white w-full text-xl'
              style={{ background: 'rgba(0,0,0,0.4)' }}
            >
              <i className='material-icons text-3xl mr-4'>video_library</i>
              {t`Show videos`}
            </button>
            <div className='w-3' />
            {sessionCode && (
              <>
                <button
                  onClick={handleOpenSessionCodeDialog}
                  className='p-5 my-2 text-gray-200 text-base flex items-center rounded-lg text-white w-full text-xl'
                  style={{ background: 'rgba(0,0,0,0.4)' }}
                >
                  <i className='material-icons text-3xl mr-4'>email</i>
                  {t`Send link to email address`}
                </button>
                <EmailSessionCodeDialog
                  sessionCode={sessionCode}
                  open={emailSessionCodeDialogOpen}
                  onClose={handleCloseSessionCodeDialog}
                />
              </>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default QRCodeRoute
