import React, { FC } from 'react'

interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  icon?: string
  valid?: boolean
}

const Input: FC<Props> = ({ icon, className, valid, ...props }) => {
  return (
    <div
      className={`border-2 ${
        valid === false ? 'border-red-500' : valid === true ? 'border-primary' : 'border-white'
      } mt-2 flex-1 rounded-lg relative flex items-center ${className}`}
    >
      {icon && (
        <div className='flex-center flex-none pl-3 pr-1'>
          <i className='material-icons text-white text-xl'>{icon}</i>
        </div>
      )}
      <input className='p-3 text-white bg-transparent rounded-lg w-full flex-1 font-semibold text-base' {...props} />
    </div>
  )
}

export default Input
