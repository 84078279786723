import { CircularProgress } from '@material-ui/core'
import useSessionVideo from 'firebase/useSessionVideo'
import React, { FC } from 'react'
import { RouteComponentProps } from 'react-router'
import logo from 'assets/logo.svg'

interface Props extends RouteComponentProps<{ videoId: string }> {}

const ShareVideoRoute: FC<Props> = ({
  match: {
    params: { videoId }
  }
}) => {
  const { videoUrl } = useSessionVideo(videoId)

  return (
    <div className='flex flex-col items-center justify-center absolute inset-0 flex-col overflow-y-auto p-6'>
      <div className='flex mb-4'>
        <img src={logo} alt='logo' width={200} />
      </div>
      <div className='flex-center overflow-hidden w-full relative mt-8 rounded-lg shadow-lg' style={{ maxWidth: 800 }}>
        {videoUrl && <video autoPlay={true} controls={true} src={videoUrl ? videoUrl : ''} className='w-full' />}
        {!videoUrl && <CircularProgress size={30} style={{ color: '#fff' }} />}
      </div>
    </div>
  )
}

export default ShareVideoRoute
